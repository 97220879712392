<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <myegrn-logo />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Регистрация 🚀
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Пароль"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Согласие c
                  <b-link>политикой на обработку персональных данных</b-link>
                  и
                  <b-link>правилами сервиса</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Регистрация
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Вы уже зарегистророванны?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Войти</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyegrnLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    MyegrnLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: true,
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      const vm = this
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            email: this.userEmail,
            password: this.password,
            referal: localStorage.getItem('referal'),
          })
            .then(response => {
              if (!response.data.Data.error) {
                /*
                useJwt.setToken(response.data.Data.accessToken)
                useJwt.setRefreshToken(response.data.Data.refreshToken)
                localStorage.setItem('userData', JSON.stringify(response.data.Data.userData))
                this.$ability.update(response.data.Data.userData.ability)
                store.commit('app-ecommerce/SET_BALANCE', response.data.Data.userData.balance)
                */

                const { userData } = response.data.Data
                useJwt.setToken(response.data.Data.accessToken)
                useJwt.setRefreshToken(response.data.Data.refreshToken)
                localStorage.setItem('userDataTicks', new Date().getTime() + (24 * 60 * 10 * 1000)) // на 10 дней
                localStorage.setItem('userData', JSON.stringify(userData))
                vm.$ability.update(userData.ability)
                store.commit('app-ecommerce/SET_BALANCE', userData.balance)

                vm.$router.replace(getHomeRouteForLoggedInUser(userData.role ? userData.role : ''))
                  .then(() => {
                    vm.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Добро пожаловать в архив МойЕГРН',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Вы успешно вошли в архив МойЕГРН. Теперь вы можете начать делиться файлами и зарабатывать!',
                      },
                    })
                  })
                  .catch(error => {
                    vm.$refs.loginForm.setErrors(error.response.data.error)
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ошибка',
                    icon: 'BellIcon',
                    text: response.data.Data.error[0].email,
                  },
                })
              }
            })
            .catch(p1 => {
              // console.log('error', p1)
              try {
                if (p1.response.Date.data) {
                  this.$refs.registerForm.setErrors(p1.response.Date.data.error)
                } else {
                  this.$refs.registerForm.setErrors('Сервер не доступен, повторите попытку позднее')
                }
              } catch {
                this.$refs.registerForm.setErrors(p1)
              }
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
